import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Link from '@/components/Link'
import { LandingLayout } from '@/features/layout/components/LandingLayout'
import { Locale } from '@/types/locales'

export default function Custom404() {
  const { t } = useTranslation('generic')

  return (
    <>
      <LandingLayout>
        <div className="items-cente flex min-h-[80vh] px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main className="sm:flex">
              <p className="text-4xl font-bold tracking-tight text-violet-500 sm:text-5xl ">
                404
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
                    {t('generic.notFound.title')}
                  </h1>
                  <p className="mt-1 pt-4 text-base text-violet-950/80">
                    {t('generic.notFound.description')}
                  </p>
                </div>
                <div className="mt-10 flex flex-col gap-4 sm:border-l sm:border-transparent sm:pl-6">
                  <Link href="/">{t('generic.notFound.goBack')}</Link>
                </div>
              </div>
            </main>
          </div>
        </div>
      </LandingLayout>
    </>
  )
}

export async function getStaticProps({ locale }: { locale: Locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'generic',
        'nav',
        'features',
        'landing',
        'comparisons',
      ])),
      locale,
    },
  }
}
