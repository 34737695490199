import React, { ReactNode } from 'react'

import NextLink, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

import { preparePathname } from '@/utils/locale'

const Link = ({
  href,
  as,
  externalLink = false,
  ...props
}: LinkProps & { externalLink?: boolean; children: ReactNode }) => {
  const router = useRouter()
  const redirectedHref =
    typeof href === 'string'
      ? preparePathname(href, router.locale)
      : {
          ...href,
          pathname: preparePathname(href.pathname, router.locale),
        }

  return (
    <NextLink
      href={externalLink ? href : redirectedHref}
      as={as ? preparePathname(as, router.locale) : ''}
      locale={false}
      {...props}
    />
  )
}

export default Link
